import React from 'react';
import styled from 'styled-components';
import {message, Button, Tag, Popconfirm} from 'antd';
import {Helmet} from 'react-helmet';
import qs from 'query-string';
import {useOutlet} from 'reconnect.js';
import {ChevronLeft} from '@styled-icons/feather/ChevronLeft';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import Config from '../../../data.json';
import * as AppActions from '../../AppActions';
import WsProxy from '../../TbaWsProxy';
import DataJson from '../../../data.json';

const WS_STATE_DISPLAY = {
  0: '尚未連線',
  1: '正在連線',
  2: '已連線',
  3: '結束連線',
};

const WS_STATE_COLOR = {
  0: 'default',
  1: 'cyan',
  2: 'green',
  3: 'magenta',
};

const LS_STATE_DISPLAY = {
  '': '直播未開啟',
  on: '直播中',
  off: '直播結束',
};

const LS_STATE_COLOR = {
  '': '#2db7f5',
  on: '#f50',
  off: '#108ee9',
};

export default function AdminLiveStreamConsole(props) {
  const params = qs.parse(props.location.search);
  const [updateCnt, setUpdateCnt] = React.useState(0);
  const [liveStream, setLiveStream] = React.useState(null);
  const [playbackId, setPlaybackId] = React.useState(null);
  const [playbackToken, setPlaybackToken] = React.useState(null);
  const [product, setProduct] = React.useState(null);
  const [user] = useOutlet('user');
  const [viewCount, setViewCount] = React.useState(0);
  const [liveStreamStatus, setLiveStreamStatus] = React.useState('');
  const wsProxy = React.useRef(new WsProxy()).current;

  const [signOn, setSignOn] = React.useState(false);
  const [signId, setSignId] = React.useState(false);

  React.useEffect(() => {
    let timer;

    if (user && product && params.id) {
      wsProxy.init({
        url: 'wss://xsds0n2oal.execute-api.ap-northeast-1.amazonaws.com/prod',
        product_id: product.id,
        live_stream_id: params.id,
        token: user.token,
        is_admin: true,
        render: (data) => {
          if (data === undefined) {
            setUpdateCnt((cnt) => cnt + 1);
            return;
          }

          if (data.resp?.view_count) {
            setViewCount(parseInt(data.resp?.view_count));
          }

          if (data.resp?.live_stream_status) {
            setLiveStreamStatus(data.resp?.live_stream_status);
          }
        },
      });
      wsProxy.connect();

      timer = setInterval(() => {
        wsProxy.sendGetStatus();
      }, 30 * 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [wsProxy, user, product, params.id]);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const v = await JStorage.fetchOneDocument('live_stream', {
          id: params.id,
        });
        setLiveStream(v);

        const p = await JStorage.fetchOneDocument('product', {id: v.product});
        setProduct(p);

        if (v.permission === 'public' || !v.permission) {
          setPlaybackId(v?.playback_ids[0]?.id);
        } else if (v.permission === 'signed') {
          const resp = await ApiUtil.req(
            `${Config.apiHost}/mux/jwt?token=${user.token}`,
            {
              method: 'POST',
              data: {live_stream_id: v.id},
            },
          );

          setPlaybackId(resp.playback_id);
          setPlaybackToken(resp.mux_token);
        }

        // wait a little longer for mux library loading
        await AppActions.delay(600);
      } catch (ex) {
        console.warn('LiveStreamPage ex', ex);
        message.error('權限不足, 無法播放');
      }
      AppActions.setLoading(false);
    }

    if (user && params.id) {
      fetchData();
    }
  }, [params.id, user]);

  return (
    <Wrapper>
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/@mux/mux-player"></script>
      </Helmet>

      <BackButton onClick={() => AppActions.navigate(`/admin/live_streams`)}>
        <ChevronLeft size={24} />
        <div className="back-btn-text">返回</div>
      </BackButton>

      {product && (
        <>
          <div className="container">
            <h2 className="title">{product?.name}</h2>

            <div className="time">
              直播時間：
              {product.session.date} {product.session.start_time}-
              {product.session.end_time}
            </div>

            <div className="status">
              <Tag size="large" color={LS_STATE_COLOR[liveStreamStatus]}>
                {LS_STATE_DISPLAY[liveStreamStatus]}
              </Tag>
              <Tag size="large" color={WS_STATE_COLOR[wsProxy.state]}>
                {WS_STATE_DISPLAY[wsProxy.state]}
              </Tag>
              觀看人數： {viewCount} 人
            </div>

            <div className="control">
              {wsProxy.state === 2 && liveStreamStatus === 'on' && (
                <Popconfirm
                  title="確認關閉直播？"
                  okText="確認"
                  cancelText="取消"
                  onCancel={() => 0}
                  onConfirm={() => {
                    wsProxy.sendTurnOnOff(false);
                    AppActions.navigate('/admin/live_streams');
                  }}>
                  <Button type="danger" ghost style={{marginRight: 15}}>
                    關閉直播
                  </Button>
                </Popconfirm>
              )}

              {DataJson.env === 'stg' && wsProxy.state === 0 && (
                <Button
                  style={{marginRight: 15}}
                  onClick={() => wsProxy.connect()}>
                  CONNECT
                </Button>
              )}

              {DataJson.env === 'stg' && wsProxy.state === 2 && (
                <Button
                  style={{marginRight: 15}}
                  onClick={() => wsProxy.disconnect()}>
                  DISCONNECT
                </Button>
              )}

              {DataJson.env === 'stg' && wsProxy.state === 2 && (
                <Button
                  style={{marginRight: 15}}
                  onClick={() => wsProxy.sendEcho('hello world')}>
                  ECHO
                </Button>
              )}

              {DataJson.env === 'stg' && (
                <Button
                  danger
                  onClick={() => {
                    if (
                      window.confirm('確定要清除Redis DB嗎?\n此動作無法還原')
                    ) {
                      wsProxy.sendResetRedis();
                    }
                  }}>
                  RESET Redis (Dev Only)
                </Button>
              )}

              {wsProxy.state === 2 && liveStreamStatus === 'on' && !signOn && (
                <Popconfirm
                  title="確認開始點名？"
                  okText="確認"
                  cancelText="取消"
                  onCancel={() => 0}
                  onConfirm={async () => {
                    try {
                      const instance = await JStorage.createDocument(
                        'sign_record',
                        {
                          product_id: product.id,
                          start_time: new Date().getTime(),
                          staff: user.id,
                        },
                      );

                      setSignOn(true);
                      setSignId(instance.id);

                      wsProxy.sendSignOn(instance.id);
                      message.success('已送出「開始點名」訊息');
                    } catch (err) {
                      console.warn(err);
                      message.error('發生錯誤');
                    } finally {
                      AppActions.setLoading(false);
                    }
                  }}>
                  <Button type="primary">開始點名</Button>
                </Popconfirm>
              )}

              {wsProxy.state === 2 && liveStreamStatus === 'on' && signOn && (
                <Popconfirm
                  title="確認關閉點名？"
                  okText="確認"
                  cancelText="取消"
                  onCancel={() => 0}
                  onConfirm={async () => {
                    try {
                      const instance = await JStorage.updateDocument(
                        'sign_record',
                        {
                          id: signId,
                        },
                        {end_time: new Date().getTime()},
                      );

                      setSignOn(false);
                      setSignId(null);

                      wsProxy.sendSignClose(instance.id);
                      message.success('已送出「關閉點名」訊息');
                    } catch (err) {
                      console.warn(err);
                      message.error('發生錯誤');
                    } finally {
                      AppActions.setLoading(false);
                    }
                  }}>
                  <Button type="primary">結束點名</Button>
                </Popconfirm>
              )}
            </div>

            {liveStream && playbackId && (
              <div className="content">
                {playbackToken ? (
                  <mux-player
                    stream-type="on-demand"
                    title={liveStream.name}
                    playback-id={playbackId}
                    playback-token={playbackToken}></mux-player>
                ) : (
                  <mux-player
                    stream-type="on-demand"
                    title={liveStream.name}
                    playback-id={playbackId}></mux-player>
                )}
              </div>
            )}
          </div>

          <div className="container">
            <div className="content">{liveStream.description}</div>
            <div className="content">{product.spec}</div>
            <div className="content">{product.intro}</div>
            <div className="content">{product.remark}</div>
          </div>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  min-height: 100vh;
  background-color: var(--pageBackgroundColor);

  & > .container {
    margin: 0px auto 30px auto;
    max-width: 1024px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;

    & > .title {
      margin-bottom: 10px;
      font-weight: 600;
    }

    & > .time {
      margin-right: 10px;
      font-weight: 500;
      font-size: 1rem;
      color: var(--secondColor);
    }

    & > .status {
      margin-top: 5px;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 1rem;
      color: var(--primaryColor);
    }

    & > .control {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    & > .content {
      margin: 10px auto;
      font-size: 1rem;
      line-height: 2rem;
    }
  }

  & > .content {
    margin: 30px auto;
    max-width: 1024px;
    padding: 20px;
    background-color: white;
  }

  .row {
    display: flex;
  }
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  border-bottom: 1px solid transparent;
  padding: 5px 10px 5px 0px;
  background-color: transparent;
  cursor: pointer;

  & > .back-btn-text {
    font-size: 1.2rem;
  }

  &:hover {
    border-bottom: 1px solid black;
  }
`;
