import * as JStorage from 'rev.sdk.js/Actions/JStorage';

const WS_STATE = {
  idle: 0,
  connecting: 1,
  connected: 2,
  closing: 3,
};

class WsProxy {
  constructor() {
    this.socket = null;
    this.state = WS_STATE.idle;
    this.reqs = [];
  }

  init({url, product_id, live_stream_id, token, is_admin, render}) {
    this.url = url;
    this.product_id = product_id;
    this.live_stream_id = live_stream_id;
    this.token = token;
    this.is_admin = is_admin;
    this.render = render;
    console.log('init', this.url);
  }

  reset() {
    this.state = WS_STATE.idle;
    this.socket = null;
    this.reqs = [];
    this.render();
  }

  connect() {
    if (this.socket === null) {
      console.log('url', this.url);
      this.socket = new WebSocket(this.url);
      this.state = WS_STATE.connecting;
      this.render();

      if (this.socket) {
        this.socket.addEventListener('message', (event) => {
          try {
            const msg = JSON.parse(event.data);
            const reqIdx = this.reqs.findIndex((r) => r.id === msg.ws_msg_id);
            if (reqIdx > -1) {
              const [req] = this.reqs.splice(reqIdx, 1);
              if (req) {
                if (msg.error) {
                  req.reject(msg);
                } else {
                  req.resolve(msg);
                }
              }
            } else {
              console.log('[ws]server-event', msg);
              this.render(msg);
            }
          } catch (ex) {
            console.warn('[ws]message ex', ex);
          }
        });

        this.socket.addEventListener('open', (event) => {
          console.log('[ws]open:', event.data);
          this.state = WS_STATE.connected;
          this.render();

          if (this.is_admin) {
            this.sendAdminInitial();
          } else {
            this.sendInitial();
          }
        });

        this.socket.addEventListener('close', async (event) => {
          console.log('[ws]close:', event.data);

          this.reset();

          const instance = await JStorage.fetchOneDocument('live_stream', {
            id: this.live_stream_id,
          });

          if (instance && instance.status === 'on') {
            this.connect();
          }
        });

        this.socket.addEventListener('error', (event) => {
          console.log('[ws]error:', event.data);
        });
      }
    }

    return this.socket;
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.state = WS_STATE.closing;
    }
  }

  async _sendWsEvent(extraPayload = {}) {
    if (this.socket) {
      const msgId = `ws${new Date().getTime()}_${Math.round(
        Math.random() * 1000000,
      )}`;

      const payload = {
        product_id: this.product_id,
        ws_msg_id: msgId,
        token: this.token,
        ...extraPayload,
      };

      console.log('[ws]send', payload);
      this.socket.send(JSON.stringify(payload));

      const promise = new Promise((resolve, reject) => {
        this.reqs.push({
          id: payload.ws_msg_id,
          resolve,
          reject,
        });
      });

      try {
        const resp = await promise;
        console.log('[ws]resp', resp);
        this.render(resp);
        return resp;
      } catch (ex) {
        console.warn('[ws]resp ex', ex);
      }
    }
  }

  async sendAdminInitial() {
    return this._sendWsEvent({action: 'initial:admin'});
  }

  async sendInitial() {
    return this._sendWsEvent({action: 'initial'});
  }

  async sendEcho(message = 'hello') {
    return this._sendWsEvent({action: 'echo', message});
  }

  async sendGetStatus() {
    return this._sendWsEvent({action: 'live_stream_status'});
  }

  async sendTurnOnOff(on) {
    return this._sendWsEvent({action: on ? 'turn_on' : 'turn_off'});
  }

  async sendResetRedis() {
    return this._sendWsEvent({action: 'reset-redis'});
  }

  async sendSignOn(id) {
    return this._sendWsEvent({
      action: 'sign_on',
      message: id,
    });
  }

  async sendSignClose(id) {
    return this._sendWsEvent({
      action: 'sign_close',
      message: id,
    });
  }
}

export default WsProxy;
